import {createSlice} from '@reduxjs/toolkit';

export const contratoSlice = createSlice({
  name: 'contrato',
  initialState: {
    contrato: null,
    isLoading: false,
    abonos: [],
    renovaciones: [],
    cuentaPorCobrar: {},
  },
  reducers: {
    setContrato: (state, action) => {
      state.Loading = false;
      state.contrato = action.payload.contrato;
    },
    setAbonos: (state, action) => {
      state.abonos = action.payload.abonos;
    },
    setRenovaciones: (state, action) => {
      state.renovaciones = action.payload.renovaciones;
    },
    setCuentaPorCobrar: (state, action) => {
      state.cuentaPorCobrar = action.payload.cuentaPorCobrar;
    },
    startLoadingContrato: (state) => {
      state.isLoading = true;
    },
  },
});

export const {
  setContrato,
  setAbonos,
  setRenovaciones,
  setCuentaPorCobrar,
  startLoadingContrato,
} = contratoSlice.actions;