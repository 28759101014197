import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import {contratoSlice} from 'redux/store/slices/Contrato';
import {modalPDFSlice} from 'redux/store/slices/ModalPDF';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    contrato: contratoSlice.reducer,
    modalPDF: modalPDFSlice.reducer,
  });
export default reducers;