import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import React from 'react';
import {inicioPagesConfigs} from './estadisticas';
import Error404 from './errorPages/Error404';
import {administracionPagesConfigs} from './Administracion';
import {avaluosPagesConfigs} from './Avaluos';
import {contratosPagesConfigs} from './Contratos';
import {egresosPagesConfigs} from 'pages/Egresos';
import {cajasPagesConfigs} from 'pages/Cajas';
import {exhoneracionesPagesConfigs} from 'pages/Exhoneraciones';
import {inversionesPagesConfigs} from 'pages/Inversiones';
import {cuentasPagesConfigs} from 'pages/CuentasPorCobrar';
import {reportesPagesConfigs} from 'pages/Reportes';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...inicioPagesConfigs,
    ...administracionPagesConfigs,
    ...avaluosPagesConfigs,
    ...contratosPagesConfigs,
    ...egresosPagesConfigs,
    ...cajasPagesConfigs,
    ...exhoneracionesPagesConfigs,
    ...inversionesPagesConfigs,
    ...cuentasPagesConfigs,
    ...reportesPagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
