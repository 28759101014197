import {createSlice} from '@reduxjs/toolkit';

export const modalPDFSlice = createSlice({
  name: 'contrato',
  initialState: {
    blob: null,
    show: false,
  },
  reducers: {
    setBlob: (state, action) => {
      state.blob = action.payload.blob;
    },
    setShow: (state) => {
      state.show = true;
    },
    setHide: (state) => {
      state.show = false;
    },
  },
});

export const {setBlob, setShow, setHide} = modalPDFSlice.actions;