import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';

const EgresosVarios = React.lazy(() => import('./EgresosPreautorizados'));
const RegistrarEgresoPreautorizado = React.lazy(() =>
  import('./RegistrarEgresosPreautorizados'),
);
export const egresosPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.egresos_preautorizados,
    path: ['/egresos-preautorizados'],
    element: <EgresosVarios />,
  },
  {
    permittedPath: RoutePermittedPath.registro_egresos_preautorizados,
    path: ['/registrar-egresos-preautorizados'],
    element: <RegistrarEgresoPreautorizado />,
  },
];
