import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';

const Exhoneraciones = React.lazy(() => import('./List'));
export const exhoneracionesPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.exhoneracinoes,
    path: ['/exhoneraciones'],
    element: <Exhoneraciones />,
  },
];