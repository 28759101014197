import React, {useEffect, useState} from 'react';
import {PAGINACION} from '@APP/utility/Enums/paginacion-enum';
import {useDispatch} from 'react-redux';
import jwtAxios from '@APP/services/auth/jwt-auth/jwt-api';
import {showPDF} from 'redux/store/slices/ModalPDF';
import {Button, Col, Dropdown, Menu, message} from 'antd';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import {FiMoreVertical} from 'react-icons/fi';
import AppAnimateGroup from '@APP/core/AppAnimateGroup';
import AppCard from '@APP/core/AppCard';
import {AppRowContainer} from '@APP/index';
import AppsPagination from '@APP/core/AppsPagination';
import AppTableContainer from '@APP/core/AppTableContainer';
import ModalRegistrarEgresosVarios from 'pages/Cajas/Egresos-varios/ModalRegistrarEgresosVarios';
import {BsReceiptCutoff} from 'react-icons/bs';

const EgresosVarios = () => {
  const [loading, setLoading] = useState(true);
  const [paginacion, setPaginacion] = useState(PAGINACION);
  const [current, setCurrent] = useState(1);
  const [inversiones, setInversiones] = useState([]);
  const [modalRegistrarAcciones, setModalRegistrarAcciones] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    await getAcciones('', paginacion.currentPage);
  }, []);

  const getAcciones = async (value = '', page = 1, order) => {
    setLoading(true);
    try {
      const {data} = await jwtAxios.get('egresos-varios', {
        params: {page, value, order},
      });
      setInversiones(data.data);
      setPaginacion(data.meta);
      setCurrent(data.meta.currentPage);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getRecibo = async (recibo) => {
    try {
      setLoading(true);
      const {data} = await jwtAxios.get('egresos-varios/recibo/' + recibo, {
        responseType: 'blob',
      });
      dispatch(showPDF(data));
    } catch {
      message.error('Ha ocurrido un error obteniendo el recibo');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const colums = [
    {
      title: 'Rubro',
      key: 'rubro',
      render: (_, {tipo_egreso_caja}) => tipo_egreso_caja?.nombre_tipo_egreso,
    },
    {
      title: 'Monto',
      key: 'monto',
      render: (_, {monto_recibido}) => (
        <NumberFormat
          value={+monto_recibido}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Q '}
        />
      ),
    },
    {
      title: 'Descripcion',
      key: 'descripcion',
      dataIndex: 'descripcion',
    },
    {
      title: 'Fecha de registro',
      key: 'fechaRegistro',
      render: (_, {fecha_registro}) => (
        <span>
          {dayjs(fecha_registro).add(6, 'hours').format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Cliente',
      key: 'cliente',
      render: (_, {cliente}) => (
        <span>
          {cliente?.nombre_cliente} {cliente?.apellido_cliente}
        </span>
      ),
    },
    {
      title: 'Usuario',
      key: 'usuario',
      render: (_, {usuarios}) => usuarios?.user_nickname,
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, inversion) => {
        const menu = (
          <Menu>
            <Menu.Item
              key='1'
              icon={<BsReceiptCutoff />}
              onClick={() => getRecibo(inversion.recibo_id)}>
              Recibo
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <a
              className='ant-dropdown-link cr-dropdown-link'
              onClick={(e) => e.preventDefault()}>
              <FiMoreVertical />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <AppAnimateGroup type='bottom'>
      <AppCard key='employee_detail'>
        <AppRowContainer>
          <Col>
            <Button
              type='primary'
              ghost
              onClick={() => setModalRegistrarAcciones(true)}>
              Registrar egresos
            </Button>
          </Col>
        </AppRowContainer>
        <AppRowContainer justify={'start'}>
          <Col>
            <AppsPagination
              count={paginacion.total}
              page={paginacion.currentPage}
              pageSize={paginacion.perPage}
              current={current}
              onChange={(page) => getAcciones('', page)}
            />
          </Col>
        </AppRowContainer>
        <AppTableContainer
          data={inversiones}
          columns={colums}
          hoverColor
          size='small'
          loading={loading}
        />
      </AppCard>
      <ModalRegistrarEgresosVarios
        open={modalRegistrarAcciones}
        onCancel={() => setModalRegistrarAcciones(false)}
        onOk={() => {
          getAcciones();
          setModalRegistrarAcciones(false);
        }}
      />
    </AppAnimateGroup>
  );
};

export default EgresosVarios;
