import axios from 'axios';
import {toast} from 'react-toastify';

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 'http://localhost:5000/api/', //YOUR_API_URL
  // HERE
  headers: {
    'Content-Type': 'application/json',
  },
  data: {},
});
jwtAxios.interceptors.response.use(
  (res) => {
    if (
      res.status === 201 ||
      (res.status === 200 && res.config.method !== 'get')
    ) {
      toast.success('Operación completada satisfactoriamente');
    }
    if (res?.data?.token) {
      localStorage.setItem('token', res.data.token);
    }
    return Promise.resolve(res);
  },
  async (err) => {
    if (err.response) {
      if (err.response.data.statusCode === 401) {
        toast.error('Su sesión ha expirado, por favor vuelva a iniciar sesión');
      } else if (err.response.data.statusCode === 400) {
        console.log(err.response.data.message);
        console.log(err.response.data.message[0]);
        toast.error(err.response.data.message.toString());
      } else if (err.response.data.statusCode === 403) {
        toast.error(
          'No tiene permisos para realizar esta acción, verifique' +
            ' sus roles asignados',
        );
      }
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    jwtAxios.defaults.headers.get['Content-Type'] = 'application/json';
    jwtAxios.defaults.headers.common['Content-Type'] = 'application/json';
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
