import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import PropTypes from 'prop-types';

const ModalPDF = ({blob, isVisible, handleCancel}) => {
  const [dataPDF, setDataPDF] = useState(null);
  useEffect(async () => {
    if (blob) {
      const blob_url = URL.createObjectURL(blob);
      setDataPDF(blob_url);
    }
  }, [blob]);

  return (
    <Modal
      style={{zIndex: '99999 !important'}}
      onCancel={handleCancel}
      cancelButtonProps={{style: {display: 'none'}}}
      className='fullview-modal'
      width={800}
      open={isVisible}
      onOk={handleCancel}
      forceRender={false}>
      {dataPDF && <iframe src={dataPDF} width='100%' height='100%' />}
    </Modal>
  );
};

export default ModalPDF;

ModalPDF.propTypes = {
  blob: PropTypes.any,
  isVisible: PropTypes.bool,
  handleCancel: PropTypes.func,
};