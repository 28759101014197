import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';

const CompendioPrendario = React.lazy(() =>
  import('pages/Reportes/compendio-prendario'),
);
const Desembolsos = React.lazy(() => import('pages/Reportes/desembolsos'));
const CarteraFinalizada = React.lazy(() =>
  import('pages/Reportes/cartera-finalizada'),
);
const Facturas = React.lazy(() => import('pages/Reportes/facturas'));
const Transferencias = React.lazy(() =>
  import('pages/Reportes/transferencias'),
);
const Abastecimientos = React.lazy(() =>
  import('pages/Reportes/abastecimientos'),
);
const CarteraVigente = React.lazy(() =>
  import('pages/Reportes/cartera-vigente'),
);
const DepositosAprobados = React.lazy(() =>
  import('pages/Reportes/depositos-aprobados'),
);
export const reportesPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.reporte_recuento_de_empeños,
    path: ['/reportes/compendio-prendario'],
    element: <CompendioPrendario />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_Desembolsos,
    path: ['/reportes/desembolsos'],
    element: <Desembolsos />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_cartera,
    path: ['/reportes/cartera-vigente'],
    element: <CarteraVigente />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_cartera,
    path: ['/reportes/cartera-finalizada'],
    element: <CarteraFinalizada />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_facturas,
    path: ['/reportes/facturas'],
    element: <Facturas />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_abastecimientos,
    path: ['/reportes/abastecimientos'],
    element: <Abastecimientos />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_transferencias,
    path: ['/reportes/transferencias'],
    element: <Transferencias />,
  },
  {
    permittedPath: RoutePermittedPath.reporte_depositos_aprobados,
    path: ['/reportes/depositos-aprobados'],
    element: <DepositosAprobados />,
  },
];
