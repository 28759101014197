import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';
import EgresosVarios from 'pages/Cajas/Egresos-varios';
const Apertura = React.lazy(() => import('pages/Cajas/Apertura'));
const Transferencias = React.lazy(() => import('pages/Cajas/Transferencias'));
const Abastecimientos = React.lazy(() => import('pages/Cajas/Abastecimientos'));
const IngresosVarios = React.lazy(() => import('pages/Cajas/Ingresos-varios'));
export const cajasPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.aperturas,
    path: '/apertura-caja',
    element: <Apertura />,
  },
  {
    permittedPath: RoutePermittedPath.transferencias,
    path: '/transferencias',
    element: <Transferencias />,
  },
  {
    permittedPath: RoutePermittedPath.abastecimientos,
    path: '/abastecimientos',
    element: <Abastecimientos />,
  },
  {
    permittedPath: RoutePermittedPath.ingresos_varios,
    path: '/ingresos-varios',
    element: <IngresosVarios />,
  },
  {
    permittedPath: RoutePermittedPath.egresos_varios,
    path: '/egresos-varios',
    element: <EgresosVarios />,
  },
];
