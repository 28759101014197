import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';

const Empleados = React.lazy(() => import('./Empleados'));
const Clientes = React.lazy(() => import('./Clientes'));
const Empresa = React.lazy(() => import('./Empresa'));
export const administracionPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.clientes,
    path: '/clientes',
    element: <Clientes />,
  },
  {
    permittedPath: RoutePermittedPath.empleados,
    path: '/empleados',
    element: <Empleados />,
  },
  {
    permittedPath: RoutePermittedPath.empresa,
    path: '/empresa',
    element: <Empresa />,
  },
];