export const authRole = {
  admin: ['user', 'admin'],
  user: ['user'],
};

export const RoutePermittedPath = {
  empresa: 'MODULO EMPRESA',
  empleados: 'MODULO EMPLEADOS',
  clientes: 'MODULO CLIENTES',
  avaluos: 'MODULO AVALUOS',
  contratos: 'MODULO CONTRATOS',
  pagos_por_deposito: 'MODULO PAGOS POR DEPOSITO',
  paso_a_venta: 'MODULO PASO A VENTA',
  egresos_preautorizados: 'MODULO EGRESOS PREAUTORIZADOS',
  contabilidad: 'MODULO CONTABILIDAD',
  abastecimientos: 'MODULO ABASTECIMIENTOS',
  transferencias: 'MODULO TRANSFERENCIAS',
  aperturas: 'MODULO APERTURAS',
  exhoneracinoes: 'MODULO EXHONERACIONES',
  inversiones: 'MODULO INVERSIONES',
  cuentas_por_cobrar: 'MODULO CUENTAS POR COBRAR',
  ingresos_varios: 'MODULO INGRESOS VARIOS',
  egresos_varios: 'MODULO EGRESOS VARIOS',
  reporte_recuento_de_empeños: 'Reporte Recuento de empeños',
  reporte_Desembolsos: 'Reporte Desembolsos',
  reporte_cartera: 'Reporte cartera vigente',
  reporte_estado_contratos: 'Reporte cartera finalizada',
  reporte_facturas: 'Reporte facturas',
  reporte_abastecimientos: 'Reporte abastecimientos',
  reporte_depositos_aprobados: 'Reporte depositos aprobados',
  reporte_transferencias: 'Reporte transferencias',
  registro_egresos_preautorizados: 'MODULO REGISTRAR EGRESOS PREAUTORIZADOS',
};

export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};

export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};

export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini-sidebar-toggle',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_HEADER_FIXED: 'hor-header-fixed',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const ThemeDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const RouteTransition = {
  NONE: 'none',
  FADE: 'fade',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  BOLD: '600',
  EXTRA_BOLD: '600',
};

export const AuthType = {
  FIREBASE: 'firebase',
  AWS_COGNITO: 'aws_cognito',
  AUTH0: 'auth0',
  JWT_AUTH: 'jwt_auth',
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: '100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: '100vh',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: '-100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: '-100vh',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: '100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: '100vw',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: '-100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: '-100vw',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: 'easeIn',
        when: 'beforeChildren',
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.15,
      },
    },
  },
};

export const AppAnimateGroups = {
  SLIDEUPIN: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.2,
        staggerChildren: 0.05,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: '100vh',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: '-100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: '-100vh',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: '100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: '100vw',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: '-100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeIn',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: '-100vw',
      transition: {
        ease: 'easeOut',
        delay: 0,
        when: 'beforeChildren',
        duration: 0.4,
        staggerChildren: 0.15,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: 'easeIn',
        when: 'beforeChildren',
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.15,
      },
    },
  },
  NOANIMATION: {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
    },
  },
};

export const AppMotionAnimate = {
  SLIDEUPIN: {
    variants: {
      hidden: {
        y: 100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    },
    transition: {
      ease: 'easeIn',
      when: 'beforeChildren',
    },
  },
  SLIDEUPOUT: {
    variants: {
      hidden: {
        y: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        y: '-100vh',
      },
    },
    transition: {
      ease: 'easeOut',
      when: 'beforeChildren',
    },
  },
  SLIDEDOWNIN: {
    variants: {
      hidden: {
        y: -100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    },
    transition: {
      ease: 'easeIn',
      when: 'beforeChildren',
    },
  },
  SLIDEDOWNOUT: {
    variants: {
      hidden: {
        y: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        y: '100vh',
      },
    },
    transition: {
      ease: 'easeOut',
      when: 'beforeChildren',
    },
  },
  SLIDELEFTIN: {
    variants: {
      hidden: {
        x: 100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        x: 0,
      },
    },
    transition: {
      ease: 'easeIn',
      when: 'beforeChildren',
    },
  },
  SLIDELEFTOUT: {
    variants: {
      hidden: {
        x: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        x: '-100vw',
      },
    },
    transition: {
      ease: 'easeOut',
      when: 'beforeChildren',
    },
  },
  SLIDERIGHTIN: {
    variants: {
      hidden: {
        x: -100,
        opacity: 0,
      },
      visible: {
        opacity: 1,
        x: 0,
      },
    },
    transition: {
      ease: 'easeIn',
      when: 'beforeChildren',
    },
  },
  SLIDERIGHTOUT: {
    variants: {
      hidden: {
        x: 0,
        opacity: 1,
      },
      visible: {
        opacity: 0,
        x: '-100vw',
      },
    },
    transition: {
      ease: 'easeOut',
      when: 'beforeChildren',
    },
  },
  FADEIN: {
    variants: {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
      },
    },
    transition: {
      duration: 0.1,
      ease: 'easeIn',
      when: 'beforeChildren',
    },
  },
  FADEOUT: {
    variants: {
      hidden: {
        opacity: 1,
      },
      visible: {
        opacity: 0,
      },
    },
    transition: {
      duration: 0.1,
      ease: 'easeOut',
      when: 'beforeChildren',
    },
  },
};
