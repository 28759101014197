import React from 'react';
import {Provider} from 'react-redux';
import './shared/styles/crema.less';
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from './@APP';
import configureStore from './redux/store';
import {BrowserRouter} from 'react-router-dom';
import * as dotenv from 'dotenv';
import JWTAuthProvider from './@APP/services/auth/jwt-auth/JWTAuthProvider';
import PermissionProvider from './@APP/utility/ComponentContextProvider/PermissionProvider';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();
dotenv.config();
const App = () => {
  return (
    <AppContextProvider>
      <ToastContainer />
      <Provider store={store}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <JWTAuthProvider>
                <AuthRoutes>
                  <PermissionProvider>
                    <AppLayout />
                  </PermissionProvider>
                </AuthRoutes>
              </JWTAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  );
};

export default App;