import {Tag, Tooltip} from 'antd';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import React from 'react';
import {GoLinkExternal} from 'react-icons/go';

const columns = () => {
  const colums = [
    {
      title: 'Código',
      key: 'codigo',
      dataIndex: 'codigo_contrato',
      render: (_, {codigo_contrato}) => (
        <>
          <span>{codigo_contrato}</span>
          <Tooltip title='Ir al contrato'>
            <GoLinkExternal
              className={'pointer'}
              size={24}
              onClick={() =>
                window.open('/contrato/' + codigo_contrato, '_blank')
              }
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      render: (_, {tipo_contrato}) => (
        <Tag color={tipo_contrato.tipo_contrato_color}>
          {tipo_contrato.nombre_contrato}
        </Tag>
      ),
    },
    {
      title: 'Estado',
      key: 'estado',
      render: (_, contrato) => (
        <>
          <Tag
            color={
              contrato.estado_contrato.rgb_estado_contrato ||
              contrato.estado_contrato.color_estado_contrato
            }>
            {contrato.estado_contrato.nombre_estado_contrato}
          </Tag>
        </>
      ),
    },
    {
      title: 'Fecha de pago',
      key: 'fechaCreacion',
      render: (_, {fecha_fin_contrato}) => (
        <span>
          {dayjs(fecha_fin_contrato).add(6, 'hours').format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Cliente',
      key: 'cliente',
      render: (_, {actores_contrato}) => (
        <span>
          {actores_contrato[0].cliente.nombre_cliente}{' '}
          {actores_contrato[0].cliente.apellido_cliente}
        </span>
      ),
    },
    {
      title: 'Monto otorgado',
      key: 'montoOtorgado',
      render: (_, {monto_total_prestamo}) => (
        <NumberFormat
          value={monto_total_prestamo}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Q '}
        />
      ),
    },
    {
      title: 'Saldo interés del mes',
      key: 'saldointeres',
      render: (_, {saldo_actual}) => (
        <NumberFormat
          value={parseFloat(saldo_actual[0].saldo_intereses).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Q '}
        />
      ),
    },
    {
      title: 'Saldo capital',
      key: 'saldocapital',
      render: (_, {saldo_actual}) => (
        <NumberFormat
          value={parseFloat(saldo_actual[0].saldo_capital).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'Q '}
        />
      ),
    },
  ];
  return colums;
};

export default columns;
