import React, {useEffect, useState} from 'react';
import {Form, InputNumber, message, Modal, Select} from 'antd';
import PropTypes from 'prop-types';
import jwtAxios from '@APP/services/auth/jwt-auth/jwt-api';
import TextArea from 'antd/es/input/TextArea';
const {Option} = Select;

const ModalRegistrarEgresosVarios = ({open, onOk, onCancel}) => {
  const [form] = Form.useForm();
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [rubros, setRubros] = useState([]);
  const [rubro, setRubro] = useState(null);
  useEffect(async () => {
    if (open) {
      const res = await jwtAxios.get('egresos-varios/rubros');
      setRubros(res.data);
    }
  }, [open]);
  const getClientes = async (value = '', page = 1) => {
    setLoading(true);
    const {data} = await jwtAxios.get('cliente', {params: {page, value}});
    setClientes(data.data);
    setLoading(false);
    return data;
  };

  const onSearch = async (value) => {
    await getClientes(value, null);
  };

  const onChange = async (value) => {
    setCliente(value);
    form.setFieldsValue({value});
  };

  const onChangeRubro = (value) => {
    setRubro(value);
    form.setFieldsValue({tipo_egreso_id: value});
  };

  return (
    <Modal
      open={open}
      onOk={async () => {
        try {
          setLoading(true);
          const values = await form.validateFields();
          await jwtAxios.post('egresos-varios', values);
          onOk();
        } catch (e) {
          if (e.errorFields) {
            message.error('Revise los campos del formulario');
          }
        } finally {
          setLoading(false);
        }
      }}
      onCancel={onCancel}
      confirmLoading={loading}>
      <Form form={form}>
        <Form.Item
          label={'Cliente'}
          name={'cliente_id'}
          rules={[{required: true, message: 'Campo requerido'}]}>
          <Select
            style={{width: '100%'}}
            showSearch
            placeholder='Seleccione Cliente'
            optionFilterProp='children'
            value={cliente}
            onChange={onChange}
            onSearch={onSearch}
            loading={loading}>
            {clientes.map((cl) => (
              <Option key={cl.cui_cliente} value={cl.id_cliente}>
                {cl.nombre_cliente} {cl.apellido_cliente} - {cl.cui_cliente}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Rubro'}
          name={'tipo_egreso_id'}
          rules={[{required: true, message: 'Campo requerido'}]}>
          <Select
            style={{width: '100%'}}
            placeholder='Seleccione rubro'
            optionFilterProp='children'
            value={rubro}
            onChange={onChangeRubro}
            loading={loading}>
            {rubros.map((cl) => (
              <Option
                key={cl.id_tipo_egreso_caja}
                value={cl.id_tipo_egreso_caja}>
                {cl.nombre_tipo_egreso}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Monto'}
          name='monto'
          rules={[{required: true, message: 'Campo requerido'}]}>
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
        <Form.Item
          label='Descripcoin'
          name='descripcion'
          rules={[{required: true, message: 'Campo requerido'}]}>
          <TextArea></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalRegistrarEgresosVarios;

ModalRegistrarEgresosVarios.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};
