import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';
const ListaInversiones = React.lazy(() =>
  import('pages/Inversiones/ListaInversiones'),
);
export const inversionesPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.inversiones,
    path: '/inversiones',
    element: <ListaInversiones />,
  },
];