import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';
const CuentasPorCobrar = React.lazy(() =>
  import('pages/CuentasPorCobrar/Lista'),
);
export const cuentasPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.cuentas_por_cobrar,
    path: '/cuentas-por-cobrar',
    element: <CuentasPorCobrar />,
  },
];