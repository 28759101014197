import React from 'react';
import {
  FcAcceptDatabase,
  FcBusinessman,
  FcDebt,
  FcDepartment,
  FcDocument,
  FcDonate,
  FcInspection,
  FcMoneyTransfer,
  FcPaid,
  FcRatings,
  FcReadingEbook,
  FcSalesPerformance,
  FcSurvey,
  FcTemplate,
  FcVoicePresentation,
} from 'react-icons/fc';
import {RoutePermittedPath} from 'shared/constants/AppEnums';
import {GiReceiveMoney} from 'react-icons/gi';
// import {RoutePermittedPath} from '../shared/constants/AppEnums';

const routesConfig = [
  {
    id: 'Sistema',
    title: 'Sistema',
    messageId: 'Sistema',
    type: 'group',
    children: [
      {
        id: 'inicio',
        title: 'Inicio',
        messageId: 'Inicio',
        type: 'item',
        icon: <FcTemplate />,
        // permittedPath: [RoutePermittedPath.dashboard],
        path: '/inicio',
      },
      {
        id: 'empresa',
        title: 'Empresa',
        messageId: 'Empresa',
        type: 'item',
        icon: <FcDepartment />,
        permittedPath: [RoutePermittedPath.empresa],
        path: '/empresa',
      },
      {
        id: 'empleados',
        title: 'Empleados',
        messageId: 'Empleados',
        type: 'item',
        icon: <FcBusinessman />,
        permittedPath: [RoutePermittedPath.empleados],
        path: '/empleados',
      },
    ],
  },
  {
    id: 'inicio',
    title: 'Clientes',
    messageId: 'Clientes',
    type: 'group',
    children: [
      {
        id: 'clientes',
        title: 'Clientes',
        messageId: 'Clientes',
        type: 'item',
        icon: <FcVoicePresentation />,
        permittedPath: [RoutePermittedPath.clientes],
        path: '/clientes',
      },
    ],
  },
  {
    id: 'Avaluos',
    title: 'Avaluos',
    messageId: 'Avaluos',
    type: 'group',
    children: [
      {
        id: 'avaluos',
        title: 'Avaluos',
        messageId: 'Avaluos',
        type: 'item',
        icon: <FcDebt />,
        permittedPath: [RoutePermittedPath.avaluos],
        path: '/avaluos',
      },
    ],
  },
  {
    id: 'Contratos',
    title: 'Contratos',
    messageId: 'Contratos',
    type: 'group',
    children: [
      {
        id: 'contratos',
        title: 'Contratos',
        messageId: 'Contratos',
        type: 'item',
        icon: <FcSurvey />,
        permittedPath: [RoutePermittedPath.contratos],
        path: '/contratos',
      },
      {
        id: 'pagos-por-deposito',
        title: 'Pagos por depósito',
        messageId: 'Pagos por depósito',
        type: 'item',
        icon: <FcReadingEbook />,
        permittedPath: [RoutePermittedPath.pagos_por_deposito],
        path: '/pagos-por-deposito',
      },
      {
        id: 'contratos-paso-a-venta',
        title: 'Paso a venta',
        messageId: 'Paso a venta',
        type: 'item',
        icon: <FcPaid />,
        permittedPath: [RoutePermittedPath.paso_a_venta],
        path: '/contratos-paso-a-venta',
      },
      {
        id: 'exhoneraciones',
        title: 'Exhoneraciones',
        messageId: 'Exhoneraciones',
        type: 'item',
        icon: <FcDonate />,
        permittedPath: [RoutePermittedPath.exhoneracinoes],
        path: '/exhoneraciones',
      },
      {
        id: 'cuentas-por-cobrar',
        title: 'Cuentas por cobrar',
        messageId: 'Cuentas por cobrar',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.cuentas_por_cobrar],
        path: '/cuentas-por-cobrar',
      },
    ],
  },
  {
    id: 'Reportes',
    title: 'Reportes',
    messageId: 'Reportes',
    type: 'group',
    children: [
      {
        id: 'reporte-conteo-prendario',
        title: 'Conteo de empeños',
        messageId: 'Conteo de empeños',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_recuento_de_empeños],
        path: '/reportes/compendio-prendario',
      },
      {
        id: 'reporte-desembolsos',
        title: 'Desembolsos',
        messageId: 'Desembolsos',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_Desembolsos],
        path: '/reportes/desembolsos',
      },
      {
        id: 'reporte-cartera-vigente',
        title: 'Cartera vigente',
        messageId: 'Cartera vigente',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_cartera],
        path: '/reportes/cartera-vigente',
      },
      {
        id: 'reporte-cartera-finalizada',
        title: 'Cartera finalizada',
        messageId: 'Cartera finalizada',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_estado_contratos],
        path: '/reportes/cartera-finalizada',
      },
      {
        id: 'reporte-facturas',
        title: 'Facturas emitidas',
        messageId: 'Facturas emitidas',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_facturas],
        path: '/reportes/facturas',
      },
      {
        id: 'reporte-abastecimientos',
        title: 'Abastecimientos',
        messageId: 'Abastecimientos',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_abastecimientos],
        path: '/reportes/abastecimientos',
      },
      {
        id: 'reporte-transferencias',
        title: 'Transferencias',
        messageId: 'Transferencias',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_abastecimientos],
        path: '/reportes/transferencias',
      },
      {
        id: 'reporte-depositos-aprobados',
        title: 'Depositos aprobados',
        messageId: 'Depositos aprobados',
        type: 'item',
        icon: <FcDocument />,
        permittedPath: [RoutePermittedPath.reporte_depositos_aprobados],
        path: '/reportes/depositos-aprobados',
      },
    ],
  },
  {
    id: 'Cajas',
    title: 'Cajas',
    messageId: 'Cajas',
    type: 'group',
    children: [
      {
        id: 'apertura-caja',
        title: 'Apertura',
        messageId: 'Apertura',
        type: 'item',
        icon: <FcInspection />,
        permittedPath: [RoutePermittedPath.aperturas],
        path: '/apertura-caja',
      },
      {
        id: 'registrar-egresos-preautorizados',
        title: 'Registrar pre-autorizados',
        messageId: 'Registrar pre-autorizados',
        type: 'item',
        icon: <FcAcceptDatabase />,
        permittedPath: [RoutePermittedPath.registro_egresos_preautorizados],
        path: '/registrar-egresos-preautorizados',
      },
      {
        id: 'egresos-preautorizados',
        title: 'Egresos pre-autorizados',
        messageId: 'Egresos pre-autorizados',
        type: 'item',
        icon: <FcRatings />,
        permittedPath: [RoutePermittedPath.egresos_preautorizados],
        path: '/egresos-preautorizados',
      },
      {
        id: 'abastecimientos',
        title: 'Abastecimientos',
        messageId: 'Abastecimientos',
        type: 'item',
        icon: <GiReceiveMoney color={'#2f64d6'} />,
        permittedPath: [RoutePermittedPath.abastecimientos],
        path: '/abastecimientos',
      },
      {
        id: 'transferencias',
        title: 'Transferencias',
        messageId: 'Transferencias',
        type: 'item',
        icon: <FcMoneyTransfer />,
        permittedPath: [RoutePermittedPath.transferencias],
        path: '/transferencias',
      },
      {
        id: 'inversiones',
        title: 'Inversiones',
        messageId: 'Inversiones',
        type: 'item',
        icon: <FcSalesPerformance />,
        permittedPath: [RoutePermittedPath.inversiones],
        path: '/inversiones',
      },
      {
        id: 'ingresos_varios',
        title: 'Ingresos varios',
        messageId: 'Ingresos varios',
        type: 'item',
        icon: <FcSalesPerformance />,
        permittedPath: [RoutePermittedPath.ingresos_varios],
        path: '/ingresos-varios',
      },
      {
        id: 'egresos_varios',
        title: 'Egresos varios',
        messageId: 'Egresos varios',
        type: 'item',
        icon: <FcSalesPerformance />,
        permittedPath: [RoutePermittedPath.egresos_varios],
        path: '/egresos-varios',
      },
    ],
  },
];
export default routesConfig;
